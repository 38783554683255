
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import {
  Card,
  Tag,
  Button,
  Icon,
  Image as VanImage,
  Swipe,
  SwipeItem,
  Row,
} from "vant";
import { Mixin } from "@/core/mixins/mixin";
import MySwiper from "@/components/MySwiper.vue";
@Component({
  components: {
    [Card.name]: Card,
    [Tag.name]: Tag,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Row.name]: Row,
    MySwiper,
  },
})
export default class mallProList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public mallRecommendList!: Array<good.CommodityDto>;
  @Prop({
    type: String,
    default: "",
  })
  public keyword!: "";
  @Watch("mallRecommendList", { deep: true })
  changeMallRecommendList(val: Array<good.CommodityDto>): void {
    this.mallRecommendList = val;
  }
  goGoodDetail(commodityId: number | undefined): void {
    this.$router.push(`/mall/details?commodityId=${commodityId}`);
  }
  lineheight(str?: string): string {
    let string = str || "";
    if (this.keyword) {
      let reg = new RegExp(this.keyword, "g");
      string = (str as string).replace(reg, "<em>" + this.keyword + "</em>");
    }
    return string;
  }
}
