var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"search-page"},[_c('van-row',[_c('van-search',{staticStyle:{"color":"#111111"},attrs:{"placeholder":_vm.placeholderWord.length ? _vm.placeholderWord : '搜索演出、分类、场馆',"maxlength":50,"show-action":"","shape":"round"},on:{"search":_vm.goJumpPage,"focus":_vm.focusSearch,"blur":function($event){_vm.isFocused = false}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('van-image',{staticClass:"searchicon",attrs:{"width":"0.29rem","height":"0.29rem","src":require('@/assets/images/Public/search.png')}})]},proxy:true},{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}})]},proxy:true},{key:"action",fn:function(){return [_c('span',{staticStyle:{"font-size":"0.3rem"},on:{"click":_vm.goJumpPage}},[_vm._v("搜索")])]},proxy:true}]),model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}})],1),_c('van-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHistory || _vm.isShowAssociate),expression:"isShowHistory || isShowAssociate"}],staticClass:"safe-wrapper padding-common"},[_c('SearchHistory',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowHistory),expression:"isShowHistory"}],ref:"search-history",attrs:{"search-keyword":_vm.searchKeyword},on:{"choose-keyword":_vm.clickSearchHistory}}),(_vm.isShowAssociate)?_c('AutoComplete',{ref:"auto-complete",attrs:{"search-keyword":_vm.searchKeyword},on:{"choose-keyword":_vm.setKeywordAndSearch}}):_vm._e()],1),(!_vm.isShowAssociate && !_vm.isShowHistory && !_vm.showloadding)?_c('van-row',[(
        _vm.typeCountMap.show > 0 ||
        _vm.typeCountMap.mall > 0 ||
        _vm.typeCountMap.yuboya > 0
      )?_c('van-tabs',{staticClass:"searchResult",attrs:{"sticky":"","title-active-color":"#111111"},on:{"click":_vm.clickTab},model:{value:(_vm.searchResultTabModel),callback:function ($$v) {_vm.searchResultTabModel=$$v},expression:"searchResultTabModel"}},_vm._l((_vm.searchResultTabs),function(item,index){return _c('van-tab',{key:index,attrs:{"name":item.type,"disabled":item.type == 'none'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(item.title)+" "),_c('span',{staticClass:"active-num"},[_vm._v(" "+_vm._s(_vm.getTabNumber(item.type))+" ")])])]},proxy:true}],null,true)},[(item.type == 'mall')?_c('div',[_c('MallResult',{key:"search-mall",ref:"mall-result",refInFor:true,attrs:{"search-keyword":_vm.searchKeyword},on:{"loading-change":_vm.mallLoadingChange,"complete":_vm.mallResultComplete}})],1):_vm._e(),(item.type == 'show')?_c('div',[_c('toursList',{ref:"tours",refInFor:true,attrs:{"tour-list":_vm.toursList}}),_c('ShowResult',{key:"search-show",attrs:{"shows":_vm.shows,"keyword":_vm.searchKeyword,"finished":_vm.showFinished},on:{"load":_vm.showLoad},model:{value:(_vm.showLoading),callback:function ($$v) {_vm.showLoading=$$v},expression:"showLoading"}})],1):_vm._e(),(item.type == 'yuboya')?_c('div',{staticClass:"yuboya-content"},[_c('showYuboya',{attrs:{"key-word":_vm.searchKeyword,"yuboya-list":_vm.yuboyaList,"finished":_vm.yuboyaFinished},on:{"load":_vm.yuboyaLoad},model:{value:(_vm.yuboyaLoading),callback:function ($$v) {_vm.yuboyaLoading=$$v},expression:"yuboyaLoading"}})],1):_vm._e()])}),1):_c('van-empty',{attrs:{"image":require('@/assets/images/search-empty.png'),"description":'暂无搜索结果，为您推荐以下' +
        (_vm.fromType == 'mall'
          ? '商品'
          : _vm.fromType == 'yuboya'
          ? '娱伯牙'
          : '演出')}})],1):_vm._e(),(
      !_vm.showloadding &&
      !_vm.isShowAssociate &&
      (_vm.isShowHistory ||
        (_vm.typeCountMap.show < 1 &&
          _vm.typeCountMap.mall < 1 &&
          _vm.typeCountMap.yuboya < 1))
    )?_c('van-row',[_c('h3',{staticClass:"recommend-title padding-common"},[_vm._v("为你推荐")]),(_vm.fromType == 'mall')?_c('MallResult',{key:"search-mall-recommend",ref:"mall-result-recommend",attrs:{"search-keyword":_vm.searchKeyword,"max-count":10,"recommend":""}}):_c('ShowResult',{key:"search-show-recommend",attrs:{"shows":_vm.recommendShows,"finished":true},model:{value:(_vm.recommendShowLoading),callback:function ($$v) {_vm.recommendShowLoading=$$v},expression:"recommendShowLoading"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }