var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mallProList"},[_c('div',{staticClass:"good-wrapper"},_vm._l((_vm.mallRecommendList),function(item,index){return _c('div',{key:index,staticClass:"goodList",on:{"click":function($event){return _vm.goGoodDetail(item.commodityInfoId)}}},[_c('van-row',{staticClass:"left"},[(item.commoditiesPicture && item.commoditiesPicture[0])?_c('van-image',{staticClass:"good-image",attrs:{"fit":_vm.imageFit,"src":item.commoditiesPicture[0],"lazy-load":""},scopedSlots:_vm._u([{key:"error",fn:function(){return [_c('van-image',{attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/placeholder/no-project.png')}})]},proxy:true}],null,true)}):_vm._e(),(item.remainStock && item.remainStock < 1)?_c('p',{staticClass:"remainStock"}):_vm._e(),(item.remainStock && item.remainStock < 1)?_c('van-image',{staticClass:"no-stock-img",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Search/no-stock.png')}}):_vm._e(),(item.limitedGrade)?_c('van-image',{staticClass:"vip-sign",attrs:{"fit":_vm.imageFit,"src":require('@/assets/images/Public/vip.png')}}):_vm._e()],1),_c('van-row',{staticClass:"right good-content"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.lineheight(item.commodityTitle))}}),(item.commodityLabel)?_c('p',{staticClass:"good-tag"},[_c('span',[_vm._v(_vm._s(item.commodityLabel))])]):_vm._e(),(item.lowestMemberPrices)?_c('p',{staticClass:"good-price"},[(
              item.lowestMemberPrices[0].amount === 0 &&
              item.lowestMemberPrices[0].integral === 0 &&
              item.lowestMemberPrices[0].printing === 0
            )?[_c('span',[_vm._v(_vm._s(item.lowestMemberPrices[0].amount))]),_c('span',{staticClass:"unit"},[_vm._v("元")]),(item.lowestMemberPrices[0].isLow === '1')?_c('span',{staticClass:"low"},[_vm._v("起 ")]):_vm._e()]:[(Number(item.lowestMemberPrices[0].integral) > 0)?[_c('span',[_vm._v(_vm._s(item.lowestMemberPrices[0].integral))]),_c('span',{staticClass:"unit"},[_vm._v("积分")])]:_vm._e(),(
                Number(item.lowestMemberPrices[0].integral) > 0 &&
                Number(item.lowestMemberPrices[0].printing) > 0
              )?_c('span',{staticClass:"plus"},[_vm._v("+")]):_vm._e(),(Number(item.lowestMemberPrices[0].printing) > 0)?[_c('span',[_vm._v(_vm._s(item.lowestMemberPrices[0].printing))]),_c('span',{staticClass:"unit"},[_vm._v("印花")])]:_vm._e(),(
                (Number(item.lowestMemberPrices[0].integral) > 0 ||
                  Number(item.lowestMemberPrices[0].printing) > 0) &&
                Number(item.lowestMemberPrices[0].amount) > 0
              )?_c('span',{staticClass:"plus"},[_vm._v("+")]):_vm._e(),(Number(item.lowestMemberPrices[0].amount) > 0)?[_c('span',[_vm._v(_vm._s(item.lowestMemberPrices[0].amount))]),_c('span',{staticClass:"unit"},[_vm._v("元")])]:_vm._e(),(item.lowestMemberPrices[0].isLow === '1')?_c('span',{staticClass:"low"},[_vm._v("起 ")]):_vm._e()]],2):_vm._e()])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }