
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Image, Row, Col, Icon } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [Image.name]: Image,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
  },
  filters: {
    formDate(date: string): string {
      const dt = new Date(Number(date));
      const y = dt.getFullYear();
      const m = (dt.getMonth() + 1 + "").padStart(2, "0");
      const d = (dt.getDate() + "").padStart(2, "0");
      return `${y}-${m}-${d}`;
    },
  },
})
export default class showProList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public projectList!: Array<good.RecommendProductDto>;
  @Watch("projectList", { deep: true })
  changeProjectList(val: Array<good.RecommendProductDto>): void {
    this.projectList = val;
  }
  @Prop({
    type: Number,
    default: () => {
      -1;
    },
  })
  public scrollTop!: number;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  categoryId!: string;
  @Prop({
    type: String,
    default: () => {
      return "";
    },
  })
  keyWord!: string;
  goProDetail(item: cms.YbyContentDetailVO): void {
    // this.$router.push(`/Yoboya-detail/${item.id}`);
    window.location.href = location.origin + "/third/#/yuboya/" + item.id;
  }
  lineheight(str?: string): string {
    let reg = new RegExp(this.keyWord, "g");
    let string = (str as string).replace(reg, "<em>" + this.keyWord + "</em>");
    return string;
  }
  // 将见解转化成纯文本
  repalceHtmlToText(str: string): string {
    str = str.replace(/<\/?.+?>/g, "");
    str = str.replace(/&nbsp;/g, "");
    return str;
  }
}
