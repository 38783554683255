
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Search,
  Sticky,
  Icon,
  Image,
  Toast,
  Empty,
} from "vant";
import ShowResult from "@/views/Searchs/component/ShowResult.vue";
import showYuboya from "@/views/Searchs/component/showYuboya.vue";
import toursList from "@/components/toursList.vue";
import MallResult from "@/views/Searchs/component/MallResult.vue";
import AutoComplete from "@/views/Searchs/component/AutoComplete.vue";
import SearchHistory from "@/views/Searchs/component/SearchHistory.vue";
import CateTabs from "@/views/Searchs/component/CateTabs.vue";
import { CommodityDtos } from "@/shims.decorator";
import { monitorEvent } from "@/utils/youMengMonitor";
import { Route, NavigationGuardNext } from "vue-router";
export type CategoryType = "show" | "mall" | "none" | "yuboya";

@Component({
  name: "searchPage",
  components: {
    showYuboya,
    ShowResult,
    toursList,
    MallResult,
    AutoComplete,
    SearchHistory,
    CateTabs,
    [Empty.name]: Empty,
    [Row.name]: Row,
    [Col.name]: Col,
    [Search.name]: Search,
    [Sticky.name]: Sticky,
    [Icon.name]: Icon,
    [Image.name]: Image,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
  },
})
export default class SearchPage extends Mixins(Mixin) {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      //当不是从演出详情或者商品详情来时，重置数据
      if (from.name != "projectdetail" && from.name != "mall-details") {
        (vm as SearchPage).refreshData();
      } else {
        console.log((vm as SearchPage).shows, "shows");
      }
    });
  }
  searchKeyword = ""; // 搜索关键字
  isFocused = false; // 搜索框是否有焦点
  isShowAssociate = false; // 是否展示【联想】
  fromType = "show"; // 从演出还是商城进入的搜索页
  searchResultTabModel = "show"; // 搜索结果选中的 Tab
  // 搜索结果中，每个类型有多少搜索结果
  typeCountMap: Record<CategoryType, number> = {
    mall: 0,
    show: 0,
    yuboya: 0,
    none: 0,
  };
  /**
   * van-tabs 中的 tab 数据不要用计算属性
   * 因为计算属性是实时变化的，而变化的中间状态可能会让 searchResultTabModel 变为错误的值
   * 并且变的是 van-tabs 内部的值，实际 v-model 对应的 data 属性并没变
   */
  searchResultTabs: Array<{ type: string; title: string }> = [];

  shows: Array<good.RecommendProductDto> = []; // 演出搜索结果数据（非巡演）
  showFinished = false; // 演出：分页搜索是否完成
  showLoading = false; // 演出：是否正在异步请求数据
  showCurrentPage = 1; // 演出：当前页码

  toursList: Array<good.RecommendProductDto> = []; // 巡演数据

  recommendShows: Array<good.RecommendProductDto> = []; // 推荐（演出）
  recommendShowLoading = false; // 推荐（演出）：是否正在异步请求数据

  mallGoods: Array<CommodityDtos> = []; // 商品：搜索结果数据
  mallFinished = false; // 商品：分页搜索是否完成
  mallLoading = false; // 商品：是否正在异步请求数据
  mallCurrentPage = 1; // 商品：当前页码

  isMallLoading = false; // 商品是否正在异步请求数据
  tourData: Array<good.RecommendProductDto> = []; // 巡演搜索结果数据
  mallData: Array<CommodityDtos> = []; // 商城搜索结果数据

  // 娱伯牙
  yuboyaLoading = false;
  yuboyaList: Array<good.YbyContentSearchListVO> = [];
  yuboyaFinished = false;
  yuboyaCurrentPage = 1;
  placeholderWord = "";
  showloadding = false; //是否正在搜索
  // 是否展示搜索历史，搜索关键字为空字符串时展示
  get isShowHistory(): boolean {
    return this.searchKeyword == "";
  }
  get searParams(): any {
    return {
      advertName: this.$route.query.advertName,
      keyword: this.$route.query.keyword,
      linkId: this.$route.query.linkId,
      linkType: this.$route.query.linkType,
      linkUrl: this.$route.query.linkUrl,
    };
  }
  /**
   * 监听搜索关键字的变化
   *
   * @param searchKeyword 搜索关键字
   */
  @Watch("searchKeyword")
  searchKeywordChange(searchKeyword: string): void {
    if (searchKeyword == "") {
      // 关闭【联想】
      this.isShowAssociate = false;
      /**
       * 每次关键字变成空字符串时，渲染历史关键字并查询【推荐】数据
       * 因为展示搜索历史时总是要展示【推荐】
       */
      // this.$router.replace("/search/show");
      this.renderHistoryAndSearchRecommend();
    } else if (
      (this.isFocused && !this.searParams.keyword) ||
      this.searParams.keyword !== searchKeyword
    ) {
      //如果 没有有关键字 或者 重新输入关键字
      /**
       * 每次关键字变成非空且搜索框有焦点时，打开并渲染【联想】
       * 搜索关键字没变化时，即便搜索关键字为非空且搜索框有焦点，也不展示【联想】
       */
      this.isShowAssociate = true;
      this.renderAssociate();
    }
  }

  @Watch("$route")
  routeChange(): void {
    window.location.reload();
  }
  refreshData(): void {
    this.resetData();
    // 从演出还是商城来的
    let fromSign = this.$route.params.sign;
    if (fromSign) {
      this.fromType = fromSign; // 从演出还是商城进入的搜索页
      this.searchResultTabModel = fromSign; // van-tabs 的 v-model
    }
    // let keyword = this.$route.query.keyword;
    // if (keyword) {
    //   this.searchKeyword = String(keyword);
    //   this.doSearch();
    // }
  }
  // 重置数据
  resetData(): void {
    this.searchKeyword = "";
    this.isFocused = true;
    this.isShowAssociate = false;
    this.fromType = "show";
    this.searchResultTabModel = "show";
    this.searchResultTabs = [];
    this.shows = [];
    this.showFinished = false;
    this.showLoading = false;
    this.showCurrentPage = 1;
    this.mallGoods = [];
    this.mallFinished = false;
    this.mallLoading = false;
    this.mallCurrentPage = 1;
  }
  // 搜索结果的 Tab 标签列表
  setSearchResultTabs(): void {
    this.searchResultTabModel = this.$route.params.sign;
    let searchResultTabs: Array<{ type: string; title: string }> = [];
    let typeCountMap = this.typeCountMap;
    if (this.fromType == "mall") {
      if (typeCountMap.mall > 0) {
        searchResultTabs.push({ type: "mall", title: "商品" });
      }
      if (typeCountMap.show > 0) {
        searchResultTabs.push({ type: "show", title: "演出" });
      }
      if (typeCountMap.yuboya > 0) {
        searchResultTabs.push({ type: "yuboya", title: "娱伯牙" });
      }
    } else if (this.fromType == "show") {
      if (typeCountMap.show > 0) {
        searchResultTabs.push({ type: "show", title: "演出" });
      }
      if (typeCountMap.mall > 0) {
        searchResultTabs.push({ type: "mall", title: "商品" });
      }
      if (typeCountMap.yuboya > 0) {
        searchResultTabs.push({ type: "yuboya", title: "娱伯牙" });
      }
    } else {
      if (typeCountMap.yuboya > 0) {
        searchResultTabs.push({ type: "yuboya", title: "娱伯牙" });
      }
      if (typeCountMap.show > 0) {
        searchResultTabs.push({ type: "show", title: "演出" });
      }
      if (typeCountMap.mall > 0) {
        searchResultTabs.push({ type: "mall", title: "商品" });
      }
    }
    let length = 4 - searchResultTabs.length;
    for (let i = 0; i < length; i++) {
      searchResultTabs.push({ type: "none", title: "" });
    }
    this.searchResultTabs = searchResultTabs;
  }

  /**
   * 刷新巡演
   */
  refreshTour(): void {
    this.$api.goodApi.product.searchTours(
      {
        keyword: this.searchKeyword,
        flagshipStore: false,
        tour: true,
      },
      ({ data }) => {
        this.toursList = data;
      }
    );
  }
  // 刷新娱伯牙
  refreshYuboya(): void {
    this.yuboyaLoading = true;
    let firstPage = 1;
    this.yuboyaCurrentPage = firstPage;
    this.yuboyaList = [];
    this.searchYuboya(firstPage);
  }

  /**
   * 刷新演出列表
   */
  refreshShow(): void {
    this.showLoading = true;
    let firstPage = 1;
    this.showCurrentPage = firstPage;
    this.shows = [];
    this.searchShow(firstPage);
  }
  // 娱伯牙：滚动分页
  yuboyaLoad(): void {
    this.searchYuboya(++this.yuboyaCurrentPage);
  }
  /**
   * 演出：滚动分页事件
   */
  showLoad(): void {
    this.searchShow(++this.showCurrentPage);
  }
  // 娱伯牙，异步搜索
  searchYuboya(currentPage: number): void {
    this.$api.goodApi.ybyContent.searchProducts(
      {
        keyword: this.searchKeyword,
        page: currentPage,
        size: this.pageSize,
      },
      (data) => {
        this.yuboyaList = this.yuboyaList.concat(
          data.data.records as Array<any>
        );
        this.yuboyaFinished = this.isPageFinished(currentPage, data.data);
        this.yuboyaLoading = false;
      }
    );
  }

  /**
   * 演出：异步搜索
   */
  searchShow(currentPage: number): void {
    this.$api.goodApi.product.searchProductsData(
      {
        keyword: this.searchKeyword,
        flagshipStore: false,
        tour: false,
        page: currentPage,
        size: this.pageSize,
      },
      ({ data }) => {
        this.shows = this.shows.concat(data.records || []);
        this.showFinished = this.isPageFinished(currentPage, data);
        this.showLoading = false;
      }
    );
  }

  /**
   * 刷新推荐演出
   */
  refreshRecommendShow(): void {
    this.recommendShowLoading = true;
    this.recommendShows = [];
    this.$api.goodApi.product.getRecommendList(
      { page: 1, size: 10, sort: "HOT_RECOMMEND" },
      ({ data }) => {
        this.recommendShows = data.records || [];
        this.recommendShowLoading = false;
      }
    );
  }

  mounted(): void {
    // 从演出还是商城来的
    let fromSign = this.$route.params.sign;
    if (fromSign) {
      this.fromType = fromSign;
      this.searchResultTabModel = fromSign;
    }
    let reg = new RegExp("undefined|null"); //不等于
    let advertName =
      !reg.test(this.searParams.keyword) &&
      ((this.searParams.keyword.length && !this.searParams.advertName) ||
        (this.searParams.keyword.length &&
          !reg.test(this.searParams.advertName) &&
          this.searParams.advertName))
        ? this.searParams.advertName
        : "";
    // 普通搜索
    if (
      this.searParams.keyword &&
      !reg.test(this.searParams.keyword) &&
      (this.searParams.linkType == "null" || !this.searParams.linkType)
    ) {
      this.searchKeyword = String(this.searParams.keyword);
      this.doSearch();
    }
    // 没有配置链接
    else if (
      advertName !== "null" &&
      this.searParams.linkType == "null" &&
      !this.searParams.linkUrl
    ) {
      this.searchKeyword = String(advertName);
    } else if (advertName !== "null" && this.searParams.linkType !== "null") {
      this.placeholderWord = String(advertName);
    }
    // 每次进入页面且应该展示历史关键字时，将"是否展开"重置为 false 并渲染历史关键字
    let searchHistory = this.$refs["search-history"] as SearchHistory;
    if (searchHistory) {
      searchHistory.resetIsShowAllHistory();
    }
    this.renderHistoryAndSearchRecommend();
  }

  clickTab(tabFlag?: string): void {
    monitorEvent(
      "SearchResult_ClickType",
      "点击搜索类型",
      tabFlag == "mall" ? "商品" : "演出"
    ); // 埋点：搜索结果页，点击搜索类型
    this.doSearch(tabFlag);
  }
  focusSearch(): void {
    this.isFocused = true;
    if (
      this.searParams.advertName &&
      this.searParams.linkType &&
      this.placeholderWord == this.searchKeyword
    ) {
      this.clearKeyword();
    }
  }

  clearKeyword(): void {
    this.searchKeyword = "";
  }
  goJumpPage(): void {
    if (
      this.$route.query.linkType !== "null" &&
      (this.placeholderWord == this.searchKeyword || !this.searchKeyword)
    ) {
      let keyword = this.searParams.keyword;
      if (this.$route.query.linkType == "SEARCH") {
        keyword = this.$route.query.keyword;
      }
      this.goToJump(
        keyword,
        this.searParams.linkId,
        this.searParams.linkType,
        decodeURIComponent(this.searParams.linkUrl)
      );
    } else {
      this.doSearch();
    }
  }

  doSearch(tabFlag?: string): void {
    let searchKeyword = this.searchKeyword
      ? this.searchKeyword
      : this.placeholderWord;
    if (searchKeyword) {
      monitorEvent("HomeSearch", "首页搜索关键词", searchKeyword); // 埋点：搜索页，首页搜索关键词
      // 合并并缓存搜索历史
      this.UserModule.mergeSearchHistory_PERSIST(searchKeyword);
      this.isShowAssociate = false;
      Toast.loading({
        loadingType: "spinner",
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.showloadding = true;
      if (
        this.searchKeyword !== this.searParams.advertName &&
        this.searchKeyword !== this.searParams.keyword
      ) {
        this.$router.replace("/search/show?keyword=" + this.searchKeyword);
      }
      this.$api.goodApi.search.findCategoryMatchCountV2(
        {
          keyword: this.searchKeyword,
        },
        ({ data }) => {
          let typeCountMap: Record<CategoryType, number> = this.typeCountMap;
          typeCountMap.mall = data.commodityCount || 0;
          typeCountMap.show = data.productCount || 0;
          typeCountMap.yuboya = data.ybyContentCount || 0;
          this.typeCountMap = typeCountMap;
          this.setSearchResultTabs();
          this.$nextTick(() => {
            /**
             * 必须在 nextTick 中设置 searchResultTabModel
             * 否则 van-tabs 内部还保持着老的 searchResultTabs，会导致计算错误
             */
            let count =
              tabFlag == "show" || tabFlag == "mall" || tabFlag == "yuboya"
                ? this.typeCountMap[tabFlag]
                : 0;
            if (count > 0) {
              this.searchResultTabModel = tabFlag || "";
            } else {
              this.searchResultTabModel = this.searchResultTabs[0].type;
            }
            Toast.clear();
            this.showloadding = false;
            if (this.searchResultTabModel == "mall") {
              this.refreshMall();
            } else if (this.searchResultTabModel == "yuboya") {
              // 查询娱伯牙文章
              this.refreshYuboya();
            } else {
              this.refreshShow(); // 查询演出数据
              this.refreshTour(); // 查询巡演数据
            }
            if (
              typeCountMap.mall < 1 &&
              typeCountMap.show < 1 &&
              typeCountMap.yuboya < 1
            ) {
              monitorEvent("SearchResult_NotFound", "无搜索结果"); // 埋点：搜索结果页，无搜索结果
              /**
               * 当演出和商品都没有数据时，tab 是不展示的
               * 这会导致无法调用 tab 下边的列表组件的方法
               * 导致无法靠列表组件弹出的查询完成事件来调用查询【推荐】的方法
               * 因此需要这里主动调用
               */
              this.searchRecommend();
            }
          });
        }
      );
    }
  }

  /**
   *  获取 Tab 对应的结果数量
   *
   * @param type 分类标识符
   */
  getTabNumber(type: string): string {
    if (
      type != "show" &&
      type != "mall" &&
      type != "none" &&
      type != "yuboya"
    ) {
      return "";
    }
    let count = this.typeCountMap[type];
    if (count > 99) {
      return "99+";
    }
    if (count < 1) {
      return "";
    }
    return String(count);
  }

  /**
   * 选择搜索历史
   *
   * @param searchKeyword 搜索关键字
   */
  clickSearchHistory(searchKeyword: string): void {
    monitorEvent("Search_ClickHistory", "点击一个搜索历史", searchKeyword); // 埋点：搜索白页，点击一个搜索历史
    this.setKeywordAndSearch(searchKeyword);
  }

  /**
   * 设置搜索关键字并搜索
   *
   * @param searchKeyword 搜索关键字
   */
  setKeywordAndSearch(searchKeyword: string): void {
    this.searchKeyword = searchKeyword;
    this.doSearch();
  }

  /**
   * 渲染联想关键字
   */
  renderAssociate(): void {
    this.$nextTick(() => {
      let autoComplete = this.$refs["auto-complete"] as AutoComplete;
      if (autoComplete) {
        autoComplete.renderAssociate();
      }
    });
  }

  /**
   * 渲染历史关键字并查询【推荐】数据
   */
  renderHistoryAndSearchRecommend(): void {
    this.$nextTick(() => {
      let searchHistory = this.$refs["search-history"] as SearchHistory;
      if (searchHistory) {
        searchHistory.renderHistory();
      }
      this.searchRecommend();
    });
  }

  /**
   * 搜索【推荐】数据
   */
  searchRecommend(): void {
    switch (this.fromType) {
      case "show":
        this.refreshRecommendShow();
        break;
      case "mall":
        this.refreshMallRecommend();
        break;
      case "yuboya":
        this.refreshRecommendShow();
        break;
    }
  }

  /**
   * 查询商品对应的数据
   */
  refreshMall(): void {
    this.$nextTick(() => {
      let mallResults = this.$refs["mall-result"] as Array<MallResult>;
      if (mallResults && mallResults.length > 0) {
        mallResults[0].refreshData();
      }
    });
  }

  /**
   * 查询商品对应的【推荐】数据
   * nextTick 保证需要的属性已经传递给了子组件的 Prop
   */
  refreshMallRecommend(): void {
    if (this.fromType == "mall") {
      this.$nextTick(() => {
        let mallResult = this.$refs["mall-result-recommend"] as MallResult;
        if (mallResult) {
          mallResult.refreshData();
        }
      });
    }
  }

  mallLoadingChange(loading: false): void {
    this.isMallLoading = loading;
  }

  /**
   * 商品搜索结果数据变化事件
   * 目的：搜索了商品数据后发现商品数据为空，则查询商品的【推荐】数据
   *
   * @param mallData 商品搜素结果
   */
  mallResultComplete(mallData: Array<CommodityDtos>): void {
    this.mallData = mallData;
    this.searchRecommend();
  }
}
